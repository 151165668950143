import { Injectable } from '@angular/core';

// import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
// import { CompanyModel, StudyModel, StudyModelRequest } from '../models/study.model';
import swal from 'sweetalert';
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProductModel, TrozaModel, ProductModelConico, FinalizarTrozaModel } from '../models/troza.model';

import { SpinnerService } from 'src/app/services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class CalcularService {

  constructor( 
    private SpinnerSvc: SpinnerService,
    private http: HttpClient 
    
  ) {}

  createTrozaService( troza: TrozaModel){
    this.SpinnerSvc.show();
    return this.http.post(`${environment.apiUrl}woods`, troza).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  updateTrozaService( troza: TrozaModel, idTroza: number){
    this.SpinnerSvc.show();
    return this.http.patch(`${environment.apiUrl}woods/${idTroza}`, troza).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  deleteTrozaService(idTroza: number){
    this.SpinnerSvc.show();
    return this.http.delete(`${environment.apiUrl}woods/${idTroza}`).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  getDetailStudyServices(idStudy:number): Observable<any> {
    return this.http.get(`${environment.apiUrl}studies/${idStudy}`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((err) => {
        throwError(err);
        return 'e';
      }));

  }

  getCompanyServices(idCompany:string){
    return this.http.get(`${environment.apiUrl}companies/${idCompany}`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((err) => {
        throwError(err);
        return 'e';
      }));
  }

  //devuelve todas las trozas de un estudio
  getTrozaStudyServices(idStudy:number){
    this.SpinnerSvc.show();
    return this.http.get(`${environment.apiUrl}woods/all/${idStudy}`).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  //calcular aserrada
  createProductServices(product: ProductModel){
    this.SpinnerSvc.show();
    return this.http.post(`${environment.apiUrl}products`, product).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  createProductConicoServices(product: ProductModelConico){
    this.SpinnerSvc.show();
    return this.http.post(`${environment.apiUrl}products/sawdust`, product).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  //actualizar producto
  updateProductServices(product: ProductModel, idProduct: number){
    this.SpinnerSvc.show();
    return this.http.patch(`${environment.apiUrl}products/${idProduct}`, product).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  updateProductConicoServices(product: ProductModel, idProduct: number){
    this.SpinnerSvc.show();
    return this.http.patch(`${environment.apiUrl}products/sawdust/${idProduct}`, product).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  //eliminar un producto
  deleteProductService(idProduct: number){
    this.SpinnerSvc.show();
    return this.http.delete(`${environment.apiUrl}products/${idProduct}`).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  //Finalizar una troza 
 finishWoodService(product: FinalizarTrozaModel){
    this.SpinnerSvc.show();
    return this.http.post(`${environment.apiUrl}woods/finished`, product).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  //obtener los productos de una troza
  getAllProductWood(idWood: number){
    this.SpinnerSvc.show();
    return this.http.get(`${environment.apiUrl}products/all/${idWood}`).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }

  especiesGet(){
    return this.http.get(`${environment.apiUrl}species`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((err) => {
        throwError(err);
        return 'e';
      }));
  }

  getResultsWood(idWood: number){
    this.SpinnerSvc.show();
    return this.http.get(`${environment.apiUrl}products/results/${idWood}`).pipe(
      map((resp: any) => {
        this.SpinnerSvc.hide();
        return resp;
      }),
      catchError((err) => {
        this.SpinnerSvc.hide();
        throwError(err);
        return 'e';
      }));
  }
  

}



